import router from 'next/router';
import store from '../redux/store';
import { getRoute, PATH } from './routes';

export const routeToCollectionPage = (collection) => {
  const storeData = store.getState().storeReducer?.store;
  router.push(
    getRoute(PATH.collectionCategories(collection?.id), storeData?.store_info?.domain),
    undefined,
    { shallow: true, scroll: true }
  );
};
